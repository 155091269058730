import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
  },
  buttonWrapper: {
    position: "relative",
    marginLeft: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

// Define the options outside the component to prevent re-creation on each render
const PROFILE_OPTIONS = [
  { label: "Service Advisor (GR)", value: "sa_gr" },
  { label: "Toyota Home Service", value: "ths" },
  { label: "MRA (GR)", value: "mra_gr" },
  { label: "Partman (GR)", value: "partman_gr" },
  { label: "Foreman (GR)", value: "foreman_gr" },
  { label: "Foreman (THS)", value: "foreman_ths" },
  { label: "Technician (GR)", value: "technician_gr" },
  { label: "Sales", value: "sales" },
  { label: "Sales Mitra", value: "sales_mitra" },
  { label: "BM", value: "bm" },
  { label: "ADH", value: "adh" },
  { label: "CRC", value: "crc" },
  { label: "GRO", value: "gro" },
  { label: "SPV Sales", value: "spv_sales" },
  { label: "IT Support", value: "it_support" },
  { label: "Accounting", value: "accounting" },
  { label: "Super Admin", value: "admin" },
];

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18n.t("validation.tooShort"))
    .max(50, i18n.t("validation.tooLong"))
    .required(i18n.t("validation.required")),
  password: Yup.string()
    .min(5, i18n.t("validation.tooShort"))
    .max(50, i18n.t("validation.tooLong"))
    .required(i18n.t("validation.required")),
  email: Yup.string()
    .email(i18n.t("validation.invalidEmail"))
    .required(i18n.t("validation.required")),
  profile: Yup.string().required(i18n.t("validation.required")),
});

const UserModal = ({ open, onClose, userId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    email: "",
    password: "",
    profile: "",
  };

  const { user: loggedInUser } = useContext(AuthContext);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);

  const methods = useForm({
    defaultValues: initialState,
    resolver: yupResolver(UserSchema),
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const { data } = await api.get(`/users/${userId}`);
        reset({
          ...data,
          profile: data.profile, // Assuming data.profile is the string value
        });
        const userQueueIds = data.queues?.map((queue) => queue.id) || [];
        setSelectedQueueIds(userQueueIds);
      } catch (err) {
        toastError(err);
      }
    };

    if (open) {
      fetchUser();
    }
  }, [userId, open, reset]);

  const handleClose = () => {
    onClose();
    reset(initialState);
    setSelectedQueueIds([]);
  };

  const handleSaveUser = async (values) => {
    const userData = { ...values, queueIds: selectedQueueIds };
    try {
      if (userId) {
        await api.put(`/users/${userId}`, userData);
      } else {
        await api.post("/users", userData);
      }
      toast.success(i18n.t("userModal.success"));
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      scroll="paper"
      aria-labelledby="user-modal-title"
    >
      <DialogTitle id="user-modal-title">
        {userId
          ? i18n.t("userModal.title.edit")
          : i18n.t("userModal.title.add")}
      </DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSaveUser)}>
          <DialogContent dividers className={classes.dialogContent}>
            <Grid container spacing={1}>
              {/* Name Field */}
              <Grid item xs={12} sm={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label={i18n.t("userModal.form.name")}
                      fullWidth
                      autoFocus
                      margin="dense"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              </Grid>

              {/* Password Field */}
              <Grid item xs={12} sm={6}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="password"
                      variant="outlined"
                      label={i18n.t("userModal.form.password")}
                      fullWidth
                      margin="dense"
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  )}
                />
              </Grid>

              {/* Email Field */}
              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label={i18n.t("userModal.form.email")}
                      fullWidth
                      margin="dense"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </Grid>

              {/* Profile Field */}
              <Grid item xs={12} sm={6}>
                <Can
                  role={loggedInUser.profile}
                  perform="user-modal:editProfile"
                  yes={() => (
                    <Controller
                      name="profile"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          options={PROFILE_OPTIONS}
                          getOptionLabel={(option) => option.label}
                          value={
                            PROFILE_OPTIONS.find(
                              (option) => option.value === value
                            ) || null
                          }
                          onChange={(_, selectedOption) => {
                            onChange(
                              selectedOption ? selectedOption.value : ""
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={i18n.t("userModal.form.profile")}
                              fullWidth
                              margin="dense"
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              {/* Queue Selection */}
              <Grid item xs={12}>
                <Can
                  role={loggedInUser.profile}
                  perform="user-modal:editQueues"
                  yes={() => (
                    <QueueSelect
                      selectedQueueIds={selectedQueueIds}
                      onChange={setSelectedQueueIds}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              disabled={isSubmitting}
              variant="outlined"
            >
              {i18n.t("userModal.buttons.cancel")}
            </Button>
            <div className={classes.buttonWrapper}>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                variant="contained"
              >
                {userId
                  ? i18n.t("userModal.buttons.okEdit")
                  : i18n.t("userModal.buttons.okAdd")}
              </Button>
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default UserModal;
