import React from "react";
import { Typography, Grid } from "@material-ui/core";
import useStyles from "./styles";

function ShippingDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { plan } = formValues;

  const parsedPlan = JSON.parse(plan);
  const { users, connections, price } = parsedPlan;

  return (
    <Grid item xs={12} sm={12}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Plan Details
      </Typography>
      <Typography gutterBottom>Users: {users}</Typography>
      <Typography gutterBottom>Connections: {connections}</Typography>
      <Typography gutterBottom>Billing: Monthly</Typography>
      <Typography gutterBottom>
        Total: R${price.toLocaleString("pt-br", { minimumFractionDigits: 2 })}
      </Typography>
    </Grid>
  );
}

export default ShippingDetails;
