import React, { useState, useEffect, useRef } from "react";
import Map, {
  Marker,
  NavigationControl,
  GeolocateControl,
  ScaleControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { getBackendURL } from "../../services/config";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Chip,
  Tooltip,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";
import dayjs from "dayjs"; // Import dayjs for date manipulation

// Mapbox access token (replace with your actual token)
const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYXJpc3VyeWFwdXRyYSIsImEiOiJjbTB5djhqa3IwcXMzMnFweXB4YWZpeGYxIn0.pFgaMl-c-JQaNKfd9hRdCg";

// Vehicle Data (Without latitude & longitude and markerColor)
const vehicleData = [
  {
    id: "1108",
    name: "1",
    pic: "RFA",
    police_no: "BP-1108-OG",
    vin: "2019",
    sim_card: "081225254017",
    description: "-",
    status: 1,
    model: "AVANZA TRANSMOVER",
  },
  {
    id: "1124",
    name: "2",
    pic: "SHM",
    police_no: "BP-1124-OG",
    vin: "2020",
    sim_card: "082266296297",
    description: "-",
    status: 1,
    model: "AVANZA TRANSMOVER",
  },
  {
    id: "1109",
    name: "3",
    pic: "MPB",
    police_no: "BP-1109-OG",
    vin: "2019",
    sim_card: "081215975857",
    description: "-",
    status: 1,
    model: "AVANZA TRANSMOVER",
  },
  {
    id: "1125",
    name: "4",
    pic: "BDA",
    police_no: "BP-1125-OG",
    vin: "2021",
    sim_card: "081330889786",
    description: "-",
    status: 1,
    model: "AVANZA TRANSMOVER",
  },
  {
    id: "1126",
    name: "5",
    pic: "WGN",
    police_no: "BP-1126-OG",
    vin: "2020",
    sim_card: "081215975860",
    description: "-",
    status: 1,
    model: "AVANZA TRANSMOVER",
  },
  {
    id: "2729",
    name: "6",
    pic: "RSP",
    police_no: "B-2729-PZJ",
    vin: "2019",
    sim_card: "082342052969",
    description: "-",
    status: 1,
    model: "AVANZA TRANSMOVER",
  },
  {
    id: "5270",
    name: "7",
    pic: "MAR",
    police_no: "DK-5270-AAJ",
    vin: "2017",
    sim_card: "081330889785",
    description: "-",
    status: 1,
    model: "HONDA VARIO",
  },
  {
    id: "4952",
    name: "8",
    pic: "SAD",
    police_no: "DK-4952-ADO",
    vin: "-",
    sim_card: "081330889803",
    description: "-",
    status: 1,
    model: "HONDA VARIO",
  },
];

// Define styles using makeStyles for Material-UI v4
const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 250,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  detail: {
    marginBottom: theme.spacing(0.5),
  },
  chipOnline: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  chipOffline: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
  },
  marker: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "12px",
    transition: "all 0.3s ease",
    position: "relative",
  },
  pulse: {
    animation: "$pulse 1s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      boxShadow: "0 0 0 0 rgba(76, 175, 80, 0.7)", // Green pulse
    },
    "70%": {
      boxShadow: "0 0 0 10px rgba(76, 175, 80, 0)", // Green pulse
    },
    "100%": {
      boxShadow: "0 0 0 0 rgba(76, 175, 80, 0)", // Green pulse
    },
  },
  tooltipContent: {
    padding: theme.spacing(2),
    backgroundColor: "#ffffff", // White background
    borderRadius: theme.spacing(1), // Rounded corners
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)", // Subtle shadow
    color: "#333333", // Dark text for readability
  },
  tooltipPaper: {
    maxWidth: 300,
    backgroundColor: "transparent", // Remove default Paper background
    boxShadow: "none", // Remove default shadow
    zIndex: 2000,
  },
  tooltipTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    color: "#1976d2", // MIUI-like primary color
  },
  tooltipDetail: {
    marginBottom: theme.spacing(0.5),
    color: "#555555", // Slightly lighter text
  },
}));

const ThsTracker = () => {
  const classes = useStyles();
  const mapRef = useRef();
  const [vehicleLocationData, setVehicleLocationData] = useState([]);
  const [viewState, setViewState] = useState({
    latitude: -8.670458,
    longitude: 115.212629,
    zoom: 8,
  });

  // State to manage blinking effect
  const [blinking, setBlinking] = useState(
    Object.fromEntries(vehicleData.map((vehicle) => [vehicle.id, false]))
  );

  // State to manage selected vehicle for persistent tooltip
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setBlinking((prev) => ({
        ...prev,
        ...Object.fromEntries(
          vehicleData.map((vehicle) =>
            vehicle.status === 1
              ? [vehicle.id, !prev[vehicle.id]]
              : [vehicle.id, false]
          )
        ),
      }));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Fetch the vehicle location data from API
  useEffect(() => {
    const fetchVehicleLocations = async () => {
      try {
        const response = await fetch(`${getBackendURL()}/ths/get-location`);
        if (!response.ok) {
          throw new Error("Failed to fetch vehicle location data");
        }
        const data = await response.json();
        console.log("Fetched vehicle locations:", data);

        // Process data to update status based on created_at
        const now = dayjs();
        const updatedData = data.map((vehicle) => {
          if (vehicle.created_at) {
            const createdAt = dayjs(vehicle.created_at);
            const diffInMinutes = now.diff(createdAt, "minute");
            if (diffInMinutes > 5) {
              return { ...vehicle, status: 0 }; // Set status to offline
            }
          }
          return vehicle;
        });

        setVehicleLocationData(updatedData);
      } catch (error) {
        console.error("Error fetching vehicle locations:", error);
      }
    };

    fetchVehicleLocations();

    const pollingInterval = setInterval(fetchVehicleLocations, 5000);
    return () => clearInterval(pollingInterval);
  }, []);

  const getVehicleWithLatestLocation = (vehicle) => {
    const locations = vehicleLocationData.filter(
      (loc) => loc.id === vehicle.id && loc.latitude && loc.longitude
    );
    if (locations.length === 0) return vehicle;

    const latestLocation = locations.reduce((latest, current) => {
      return dayjs(current.interval).isAfter(dayjs(latest.interval))
        ? current
        : latest;
    }, locations[0]);

    // Determine markerColor based on status
    const markerColor = latestLocation.status === 1 ? "green" : "red";

    return { ...vehicle, ...latestLocation, markerColor };
  };

  // Function to handle marker clicks
  const handleMarkerClick = (vehicle) => {
    // If the clicked vehicle is already selected, deselect it
    if (selectedVehicle && selectedVehicle.id === vehicle.id) {
      setSelectedVehicle(null);
    } else {
      setSelectedVehicle(vehicle);
    }
  };

  // Function to handle clicks outside markers (to close tooltip)
  const handleMapClick = () => {
    setSelectedVehicle(null);
  };

  return (
    <ClickAwayListener onClickAway={handleMapClick}>
      <div style={{ width: "100%", height: "100vh", position: "relative" }}>
        <Map
          {...viewState}
          style={{ width: "100%", height: "100%" }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxAccessToken={MAPBOX_TOKEN}
          onMove={(evt) => setViewState(evt.viewState)}
          ref={mapRef}
          onClick={handleMapClick} // Handle map clicks to close tooltip
        >
          {/* Navigation Controls */}
          <NavigationControl style={{ right: 10, top: 10 }} />
          <GeolocateControl style={{ right: 10, top: 90 }} />
          <ScaleControl style={{ bottom: 30, left: 10 }} />

          {vehicleData.map((vehicle) => {
            const vehicleWithLocation = getVehicleWithLatestLocation(vehicle);
            if (!vehicleWithLocation.latitude || !vehicleWithLocation.longitude)
              return null;

            const isSelected =
              selectedVehicle && selectedVehicle.id === vehicle.id;

            return (
              <Marker
                key={vehicle.id}
                longitude={vehicleWithLocation.longitude}
                latitude={vehicleWithLocation.latitude}
                anchor="bottom"
              >
                <Tooltip
                  title={
                    <Paper className={classes.tooltipContent} elevation={3}>
                      <Typography
                        variant="subtitle1"
                        className={classes.tooltipTitle}
                      >
                        THS {vehicleWithLocation.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.tooltipDetail}
                      >
                        <strong>Pic:</strong> {vehicleWithLocation.pic}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.tooltipDetail}
                      >
                        <strong>Police No:</strong>{" "}
                        {vehicleWithLocation.police_no}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.tooltipDetail}
                      >
                        <strong>Model:</strong> {vehicleWithLocation.model}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.tooltipDetail}
                      >
                        <strong>VIN:</strong> {vehicleWithLocation.vin}
                      </Typography>

                      <Box mt={1} mb={1}>
                        <Chip
                          label={
                            vehicleWithLocation.status === 1
                              ? "Online"
                              : "Offline"
                          }
                          className={
                            vehicleWithLocation.status === 1
                              ? classes.chipOnline
                              : classes.chipOffline
                          }
                          size="small"
                        />
                      </Box>
                      {vehicleWithLocation.interval && (
                        <Typography
                          variant="body2"
                          className={classes.tooltipDetail}
                        >
                          <strong>Timestamp:</strong>{" "}
                          {dayjs(vehicleWithLocation.interval).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </Typography>
                      )}

                      {/* Display created_at */}
                      {vehicleWithLocation.created_at && (
                        <Typography
                          variant="body2"
                          className={classes.tooltipDetail}
                        >
                          <strong>Updated At:</strong>{" "}
                          {dayjs(vehicleWithLocation.created_at).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </Typography>
                      )}
                    </Paper>
                  }
                  classes={{ tooltip: classes.tooltipPaper }}
                  placement="top"
                  arrow
                  interactive
                  open={isSelected} // Control tooltip visibility
                  PopperProps={{
                    // Ensure the tooltip is rendered in the body
                    container: document.body,
                    modifiers: [
                      {
                        name: "zIndex",
                        options: {
                          zIndex: 2000, // Match the zIndex in tooltipPaper
                        },
                      },
                    ],
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent map click
                      handleMarkerClick(vehicleWithLocation);
                    }}
                    className={`${classes.marker} ${
                      vehicleWithLocation.status === 1 && blinking[vehicle.id]
                        ? classes.pulse
                        : ""
                    }`}
                    style={{
                      backgroundColor:
                        vehicleWithLocation.status === 1
                          ? "#4caf50"
                          : "#f44336",
                      width: isSelected ? 35 : 25,
                      height: isSelected ? 35 : 25,
                      border: isSelected ? "3px solid #ffeb3b" : "none",
                    }}
                  >
                    {vehicle.name}
                  </div>
                </Tooltip>
              </Marker>
            );
          })}

          {/* Removed the Popup component and related state */}
        </Map>
      </div>
    </ClickAwayListener>
  );
};

export default ThsTracker;
