const rules = {
  user: {
    static: [],
  },
  gro: {
    static: ["drawer-gro-items:view"],
  },
  foreman_ths: {
    static: ["drawer-foreman_ths-items:view"],
  },
  admin: {
    static: [
      //"dashboard:view",
      "drawer-foreman_ths-items:view",
      "rows-page:deleteContact",
      "drawer-gro-items:view",
      "drawer-admin-items:view",
      "drawer-foremanths-items:view",
      "tickets-manager:showall",
      "user-modal:editProfile",
      "user-modal:editQueues",
      "ticket-options:deleteTicket",
      "contacts-page:deleteContact",
      "connections-page:actionButtons",
      "connections-page:addConnection",
      "connections-page:editOrDeleteConnection",
    ],
  },
};

export default rules;
