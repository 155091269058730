import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ConfirmationModal from "../ConfirmationModal";
import { Edit as EditIcon } from "@material-ui/icons";
import { toast } from "react-toastify";
import usePlans from "../../hooks/usePlans";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainPaper: {
    width: "100%",
    flex: 1,
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
  },
  tableContainer: {
    width: "100%",
    overflowX: "scroll",
    ...theme.scrollbarStyles,
  },
  textfield: {
    width: "100%",
  },
  buttonContainer: {
    textAlign: "right",
    padding: theme.spacing(1),
  },
}));

export function PlanManagerForm(props) {
  const { onSubmit, onDelete, onCancel, initialValue, loading } = props;
  const classes = useStyles();

  const [record, setRecord] = useState({
    name: "",
    users: 0,
    connections: 0,
    queues: 0,
    value: 0,
    isPublic: true,
  });

  useEffect(() => {
    setRecord(initialValue);
  }, [initialValue]);

  const handleSubmit = async (data) => {
    onSubmit(data);
  };

  return (
    <Formik
      enableReinitialize
      className={classes.fullWidth}
      initialValues={record}
      onSubmit={(values, { resetForm }) =>
        setTimeout(() => {
          handleSubmit(values);
          resetForm();
        }, 500)
      }
    >
      {(values) => (
        <Form className={classes.fullWidth}>
          <Grid spacing={2} justifyContent="flex-end" container>
            <Grid xs={12} sm={6} md={4} item>
              <Field
                as={TextField}
                label="Name"
                name="name"
                variant="outlined"
                className={classes.fullWidth}
                margin="dense"
              />
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel htmlFor="status-selection">Public</InputLabel>
                <Field
                  as={Select}
                  id="status-selection"
                  label="isPublic"
                  name="isPublic"
                  margin="dense"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Field>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <Field
                as={TextField}
                label="Value"
                name="value"
                variant="outlined"
                className={classes.fullWidth}
                margin="dense"
                type="text"
              />
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <Field
                as={TextField}
                label="Users"
                name="users"
                variant="outlined"
                className={classes.fullWidth}
                margin="dense"
                type="number"
              />
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <Field
                as={TextField}
                label="Connections"
                name="connections"
                variant="outlined"
                className={classes.fullWidth}
                margin="dense"
                type="number"
              />
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <Field
                as={TextField}
                label="Queues"
                name="queues"
                variant="outlined"
                className={classes.fullWidth}
                margin="dense"
                type="number"
              />
            </Grid>
            <Grid xs={12} item>
              <Grid justifyContent="flex-end" spacing={1} container>
                <Grid xs={4} md={1} item>
                  <ButtonWithSpinner
                    className={classes.fullWidth}
                    loading={loading}
                    onClick={() => onCancel()}
                    variant="contained"
                  >
                    Clear
                  </ButtonWithSpinner>
                </Grid>
                {record.id !== undefined ? (
                  <Grid xs={4} md={1} item>
                    <ButtonWithSpinner
                      className={classes.fullWidth}
                      loading={loading}
                      onClick={() => onDelete(record)}
                      variant="contained"
                      color="secondary"
                    >
                      Delete
                    </ButtonWithSpinner>
                  </Grid>
                ) : null}
                <Grid xs={4} md={1} item>
                  <ButtonWithSpinner
                    className={classes.fullWidth}
                    loading={loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </ButtonWithSpinner>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export function PlansManagerGrid(props) {
  const { records, onSelect } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.tableContainer}>
      <Table
        className={classes.fullWidth}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ width: "1%" }}>
              #
            </TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Users</TableCell>
            <TableCell align="center">Public</TableCell>
            <TableCell align="center">Connections</TableCell>
            <TableCell align="center">Queues</TableCell>
            <TableCell align="center">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center" style={{ width: "1%" }}>
                <IconButton onClick={() => onSelect(row)} aria-label="edit">
                  <EditIcon />
                </IconButton>
              </TableCell>
              <TableCell align="left">{row.name || "-"}</TableCell>
              <TableCell align="center">{row.users || "-"}</TableCell>
              <TableCell align="center">
                {row.isPublic ? "Yes" : "No" || "-"}
              </TableCell>
              <TableCell align="center">{row.connections || "-"}</TableCell>
              <TableCell align="center">{row.queues || "-"}</TableCell>
              <TableCell align="center">
                {row.value.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }) || "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default function PlansManager() {
  const classes = useStyles();
  const { list, save, update, remove } = usePlans();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState({
    name: "",
    users: 0,
    connections: 0,
    queues: 0,
    value: 0,
    isPublic: true,
  });

  useEffect(() => {
    async function fetchData() {
      await loadPlans();
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPlans = async () => {
    setLoading(true);
    try {
      const planList = await list();
      setRecords(planList);
    } catch (e) {
      toast.error("Unable to load the list of records");
    }
    setLoading(false);
  };

  const handleSubmit = async (data) => {
    const newData = {
      ...data,
      value: data.value.replace(",", "."),
    };
    setLoading(true);
    try {
      if (data.id !== undefined) {
        await update(newData);
      } else {
        await save(newData);
      }
      await loadPlans();
      handleCancel();
      toast.success("Operation successfully completed!");
    } catch (e) {
      toast.error(
        "Unable to complete the operation. Check if there is already a plan with the same name or if the fields are filled out correctly"
      );
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await remove(record.id);
      await loadPlans();
      handleCancel();
      toast.success("Operation successfully completed!");
    } catch (e) {
      toast.error("Unable to complete the operation");
    }
    setLoading(false);
  };

  const handleOpenDeleteDialog = () => {
    setShowConfirmDialog(true);
  };

  const handleCancel = () => {
    setRecord({
      name: "",
      users: 0,
      connections: 0,
      queues: 0,
      value: 0,
      isPublic: true,
    });
  };

  const handleSelect = (data) => {
    setRecord({
      id: data.id,
      name: data.name || "",
      users: data.users || 0,
      connections: data.connections || 0,
      queues: data.queues || 0,
      value:
        data.value.toLocaleString("en-US", { minimumFractionDigits: 2 }) || 0,
      isPublic: data.isPublic,
    });
  };

  return (
    <Paper className={classes.mainPaper} elevation={0}>
      <Grid spacing={2} container>
        <Grid xs={12} item>
          <PlanManagerForm
            initialValue={record}
            onDelete={handleOpenDeleteDialog}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} item>
          <PlansManagerGrid records={records} onSelect={handleSelect} />
        </Grid>
      </Grid>
      <ConfirmationModal
        title="Delete Record"
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={() => handleDelete()}
      >
        Are you sure you want to delete this record?
      </ConfirmationModal>
    </Paper>
  );
}
