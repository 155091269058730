import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/StarBorder";
import usePlans from "../../../hooks/usePlans";
import useCompanies from "../../../hooks/useCompanies";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  customCard: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    flexDirection: "column",
  },
  custom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default function Pricing(props) {
  const { setFieldValue, setActiveStep, activeStep } = props;
  const { list, finder } = usePlans();
  const { find } = useCompanies();
  const classes = useStyles();

  const [usersPlans, setUsersPlans] = useState(3);
  const [companiesPlans, setCompaniesPlans] = useState(0);
  const [connectionsPlans, setConnectionsPlans] = useState(3);
  const [storagePlans, setStoragePlans] = useState([]);
  const [customValuePlans, setCustomValuePlans] = useState(49.0);
  const [loading, setLoading] = useState(false);
  const companyId = localStorage.getItem("companyId");

  useEffect(() => {
    async function fetchData() {
      await loadCompanies();
    }
    fetchData();
  }, []);

  const loadCompanies = async () => {
    setLoading(true);
    try {
      const company = await find(companyId);
      setCompaniesPlans(company.planId);
      await loadPlans(company.planId);
    } catch (e) {
      console.log(e);
      // toast.error("Unable to load the list of records");
    }
    setLoading(false);
  };

  const loadPlans = async (companyPlanId) => {
    setLoading(true);
    try {
      const plan = await finder(companyPlanId);
      const plans = [
        {
          title: plan.name,
          planId: plan.id,
          price: plan.value,
          description: [
            `${plan.users} Users`,
            `${plan.connections} Connections`,
            `${plan.queues} Queues`,
          ],
          users: plan.users,
          connections: plan.connections,
          queues: plan.queues,
          buttonText: "SELECT",
          buttonVariant: "outlined",
        },
      ];
      setStoragePlans(plans);
    } catch (e) {
      console.log(e);
      // toast.error("Unable to load the list of records");
    }
    setLoading(false);
  };

  const tiers = storagePlans;

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 12}
            md={12}
          >
            <Card>
              <CardHeader
                title={tier.title}
                titleTypographyProps={{ align: "center" }}
                className={classes.cardHeader}
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography component="h2" variant="h3" color="textPrimary">
                    R$
                    {tier.price.toLocaleString("pt-br", {
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    /month
                  </Typography>
                </div>
                <ul>
                  {tier.description.map((line) => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  color="primary"
                  onClick={() => {
                    if (tier.custom) {
                      setFieldValue(
                        "plan",
                        JSON.stringify({
                          users: usersPlans,
                          connections: connectionsPlans,
                          price: customValuePlans,
                        })
                      );
                    } else {
                      setFieldValue("plan", JSON.stringify(tier));
                    }
                    setActiveStep(activeStep + 1);
                  }}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
