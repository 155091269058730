import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Grid, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ContactSchema = Yup.object().shape({
  customer: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const customerGroupOptions = [
  "PERORANGAN",
  "PEMERINTAH",
  "PERUSAHAAN",
  "RENTAL",
];

const ProfileCustomerModal = ({
  open,
  onClose,
  rowId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const initialState = {
    customer: "",
    customer_group: "",
    telephone_wa_cp: "",
    hobi: "",
    pekerjaan: "",
    makanan_fav: "",
    minuman_fav: "",
    sosmed: "",
    number: "",
    email: "",
    alamat: "",
    provinsi: "",
    vehicle: {
      vehicleType: "",
      licensePlate: "",
    },
    disableBot: false,
  };

  const [profile, setProfile] = useState(initialState);

  const [provinces, setProvinces] = useState([]);
  const [regencies, setRegencies] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);

  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedRegency, setSelectedRegency] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedVillage, setSelectedVillage] = useState(null);

  const [loadingRegencies, setLoadingRegencies] = useState(false);
  const [loadingDistricts, setLoadingDistricts] = useState(false);
  const [loadingVillages, setLoadingVillages] = useState(false);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setProfile((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!rowId) return;

      try {
        setSelectedProvince(null);
        setSelectedRegency(null);
        setSelectedDistrict(null);
        setSelectedVillage(null);

        setRegencies([]);
        setDistricts([]);
        setVillages([]);

        const { data } = await api.get("/at/profile-customer/", {
          params: { police_no: rowId },
        });
        setProfile(data);
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [rowId, open, initialValues]);

  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const { data } = await api.get("/wilayah-indoensia/provinces");
        setProvinces(data);
      } catch (err) {
        console.error("Error fetching provinces", err);
      }
    };
    fetchProvinces();
  }, []);

  useEffect(() => {
    if (selectedProvince) {
      const fetchRegencies = async () => {
        setLoadingRegencies(true);
        try {
          const { data } = await api.get(
            `/wilayah-indoensia/regencies/${selectedProvince.id}`
          );
          setRegencies(data);
        } catch (err) {
          console.error("Error fetching regencies", err);
        } finally {
          setLoadingRegencies(false);
        }
      };
      fetchRegencies();
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedRegency) {
      const fetchDistricts = async () => {
        setLoadingDistricts(true);
        try {
          const { data } = await api.get(
            `/wilayah-indoensia/districts/${selectedRegency.id}`
          );
          setDistricts(data);
        } catch (err) {
          console.error("Error fetching districts", err);
        } finally {
          setLoadingDistricts(false);
        }
      };
      fetchDistricts();
    }
  }, [selectedRegency]);

  useEffect(() => {
    if (selectedDistrict) {
      const fetchVillages = async () => {
        setLoadingVillages(true);
        try {
          const { data } = await api.get(
            `/wilayah-indoensia/villages/${selectedDistrict.id}`
          );
          setVillages(data);
        } catch (err) {
          console.error("Error fetching villages", err);
        } finally {
          setLoadingVillages(false);
        }
      };
      fetchVillages();
    }
  }, [selectedDistrict]);

  const handleClose = () => {
    onClose();
    setProfile(initialState);
  };

  const handleSaveContact = async (values) => {
    try {
      if (rowId) {
        await api.put(`/contacts/${rowId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/contacts", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("profileCustomerModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Profile Customer: {profile.police_no}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Formik
          initialValues={profile}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label={i18n.t("profileCustomerModal.form.customer")}
                      name="customer"
                      error={touched.customer && Boolean(errors.customer)}
                      helperText={touched.customer && errors.customer}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      autoHighlight
                      options={customerGroupOptions}
                      getOptionLabel={(option) => option}
                      value={values.customer_group}
                      onChange={(event, newValue) => {
                        setProfile((prevState) => ({
                          ...prevState,
                          customer_group: newValue,
                        }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={i18n.t(
                            "profileCustomerModal.form.customer_group"
                          )}
                          name="customer_group"
                          variant="outlined"
                          size="small"
                          error={
                            touched.customer_group &&
                            Boolean(errors.customer_group)
                          }
                          helperText={
                            touched.customer_group && errors.customer_group
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={i18n.t("profileCustomerModal.form.alamat")}
                      name="alamat"
                      error={touched.alamat && Boolean(errors.alamat)}
                      helperText={touched.alamat && errors.alamat}
                      placeholder="Alamat"
                      fullWidth
                      multiline
                      rows={2}
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      autoHighlight
                      name="provinsi"
                      options={provinces}
                      getOptionLabel={(option) => option.name}
                      value={selectedProvince}
                      onChange={(event, newValue) => {
                        setSelectedProvince(newValue);
                        setSelectedRegency(null);
                        setSelectedDistrict(null);
                        setSelectedVillage(null);
                        setRegencies([]);
                        setDistricts([]);
                        setVillages([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Provinsi"
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  {/* Regency Autocomplete */}
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      autoHighlight
                      options={regencies}
                      getOptionLabel={(option) => option.name}
                      value={selectedRegency}
                      onChange={(event, newValue) => {
                        setSelectedRegency(newValue);
                        setSelectedDistrict(null);
                        setSelectedVillage(null);
                        setDistricts([]);
                        setVillages([]);
                      }}
                      loading={loadingRegencies}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Kabupaten/Kota"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingRegencies ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* District Autocomplete */}
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      autoHighlight
                      options={districts}
                      getOptionLabel={(option) => option.name}
                      value={selectedDistrict}
                      onChange={(event, newValue) => {
                        setSelectedDistrict(newValue);
                        setSelectedVillage(null);
                        setVillages([]);
                      }}
                      loading={loadingDistricts}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Kecamatan"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingDistricts ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Village Autocomplete */}
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      autoHighlight
                      options={villages}
                      getOptionLabel={(option) => option.name}
                      value={selectedVillage}
                      onChange={(event, newValue) => {
                        setSelectedVillage(newValue);
                      }}
                      loading={loadingVillages}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Desa"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingVillages ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label={i18n.t("profileCustomerModal.form.hobi")}
                      name="hobi"
                      error={touched.hobi && Boolean(errors.hobi)}
                      helperText={touched.hobi && errors.hobi}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label={i18n.t("profileCustomerModal.form.pekerjaan")}
                      name="pekerjaan"
                      error={touched.pekerjaan && Boolean(errors.pekerjaan)}
                      helperText={touched.pekerjaan && errors.pekerjaan}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label={i18n.t("profileCustomerModal.form.makanan_fav")}
                      name="makanan_fav"
                      error={touched.makanan_fav && Boolean(errors.makanan_fav)}
                      helperText={touched.makanan_fav && errors.makanan_fav}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label={i18n.t("profileCustomerModal.form.minuman_fav")}
                      name="minuman_fav"
                      error={touched.minuman_fav && Boolean(errors.minuman_fav)}
                      helperText={touched.minuman_fav && errors.minuman_fav}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("profileCustomerModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {rowId
                    ? `${i18n.t("profileCustomerModal.buttons.okEdit")}`
                    : `${i18n.t("profileCustomerModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ProfileCustomerModal;
