import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Collapse,
} from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import clsx from "clsx";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ProfileCustomerModal from "../../components/ProfileCustomerModal";
import NewTicketModal from "../../components/NewTicketModal";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { SocketContext } from "../../context/Socket/SocketContext";
import { Can } from "../../components/Can";
import { i18n } from "../../translate/i18n";

// Reducer function to manage rows state
const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_ROWS":
      const newRows = action.payload.filter(
        (newRow) => !state.some((row) => row.no_booking === newRow.no_booking)
      );
      const updatedRows = state.map(
        (row) =>
          action.payload.find(
            (newRow) => newRow.no_booking === row.no_booking
          ) || row
      );
      return [...updatedRows, ...newRows];

    case "UPDATE_ROW":
      const updatedState = state.map((row) =>
        row.no_booking === action.payload.no_booking ? action.payload : row
      );
      const exists = state.some(
        (row) => row.no_booking === action.payload.no_booking
      );
      return exists ? updatedState : [action.payload, ...state];

    case "DELETE_ROW":
      return state.filter((row) => row.no_booking !== action.payload);

    case "RESET":
      return [];

    default:
      return state;
  }
};

// Styles using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(0),
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  yellowRow: {
    backgroundColor: "#f1c40f !important",
    "& .MuiTableCell-root": {
      color: "#000000 !important",
    },
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: "#024481",
    zIndex: 1,
    "& .MuiTableCell-head": {
      color: "#ffffff",
      fontWeight: "bold",
    },
  },
  iconButton: {
    "&:hover": {
      opacity: 0.8,
    },
  },
  truncatedText: {
    maxWidth: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    verticalAlign: "bottom",
  },
  buttonsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  filtersContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    flexWrap: "wrap",
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    transition: "max-height 0.5s ease, opacity 0.5s ease, padding 0.5s ease",
    overflow: "hidden",
    marginTop: theme.spacing(2),
    width: "100%",
  },
  filtersHidden: {
    maxHeight: 0,
    opacity: 0,
    padding: 0,
    marginTop: 0,
  },
  filtersVisible: {
    maxHeight: 200, // Adjust as needed
    opacity: 1,
    padding: theme.spacing(1, 2),
  },
  filterItem: {
    flex: "1 1 200px",
    minWidth: 200,
  },
  filterHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  // New style for the Title component
  title: {
    padding: 0,
    margin: 0,
  },
}));

const ReservasiRuangTunggu = () => {
  const classes = useStyles();

  const history = useHistory();

  const { user } = useContext(AuthContext);
  const socketManager = useContext(SocketContext);

  // State Hooks
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [rows, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({});
  const [profileCustomerModalOpen, setProfileCustomerModalOpen] =
    useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [showFilters, setShowFilters] = useState(false); // New state for toggle

  // Reset rows and pageNumber when searchParam or selectedDate changes
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, selectedDate]);

  // Fetch contacts data
  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/direct/get-booking-reservasi", {
            params: { searchParam, pageNumber, date: selectedDate },
          });

          if (isMounted) {
            dispatch({ type: "LOAD_ROWS", payload: data.booking });
            setHasMore(data.hasMore);
            setLoading(false);
          }
        } catch (err) {
          if (isMounted) {
            toastError(err);
            setLoading(false);
          }
        }
      };
      fetchContacts();
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
      isMounted = false;
    };
  }, [searchParam, pageNumber, selectedDate]);

  // Handle real-time updates via WebSocket
  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.GetSocket(companyId);

    const onBookingReservasi = (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_ROW", payload: data.booking });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_ROW", payload: data.rowId });
      }
    };

    socket.on(`company-${companyId}-booking-reservasi`, onBookingReservasi);

    return () => {
      socket.off(`company-${companyId}-booking-reservasi`, onBookingReservasi);
      socket.disconnect();
    };
  }, [socketManager]);

  // Handler: Search Input Change
  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  // Handler: Date Change
  const handleDateChange = (date) => {
    setSelectedDate(date ? date.toISOString().split("T")[0] : null);
  };

  // Handler: Close Contact Modal
  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  // Handler: Close Contact Modal
  const handleCloseProfileCustomerModal = () => {
    setSelectedCustomerId(null);
    setProfileCustomerModalOpen(false);
  };

  // Handler: Close or Open Ticket Modal
  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket?.uuid) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  // Handler: Edit Contact
  const handleEditContact = (rowId) => {
    setSelectedContactId(rowId);
    setContactModalOpen(true);
  };

  // Handler: Load More Rows
  const loadMore = () => {
    if (hasMore && !loading) {
      setPageNumber((prev) => prev + 1);
    }
  };

  // Handler: Scroll Event for Infinite Scrolling
  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop - clientHeight < 100) {
      loadMore();
    }
  };

  // Handler: Toggle Filters
  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MainContainer>
        {/* Update Profile Customer Modal */}
        <ProfileCustomerModal
          open={profileCustomerModalOpen}
          onClose={handleCloseProfileCustomerModal}
          aria-labelledby="form-dialog-title"
          rowId={selectedCustomerId}
        />
        {/* New Ticket Modal */}
        <NewTicketModal
          modalOpen={newTicketModalOpen}
          initialContact={contactTicket}
          onClose={handleCloseOrOpenTicket}
        />

        {/* Contact Modal */}
        <ContactModal
          open={contactModalOpen}
          onClose={handleCloseContactModal}
          aria-labelledby="form-dialog-title"
          rowId={selectedContactId}
        />

        {/* Main Header */}
        <MainHeader>
          <Typography variant="h5" color="primary" className={classes.title}>
            {i18n.t("reservasiRuangTunggu.title")}
          </Typography>
          {!showFilters && (
            <MainHeaderButtonsWrapper className={classes.buttonsWrapper}>
              <IconButton
                className={classes.filterToggleButton}
                onClick={toggleFilters}
                aria-label={
                  showFilters
                    ? i18n.t("reservasiRuangTunggu.actions.hideFilters")
                    : i18n.t("reservasiRuangTunggu.actions.showFilters")
                }
              >
                {showFilters ? <CloseIcon /> : <FilterListIcon />}
              </IconButton>
            </MainHeaderButtonsWrapper>
          )}
        </MainHeader>

        {/* Filters Section */}
        <Collapse in={showFilters} timeout="auto" unmountOnExit>
          <div
            className={clsx(classes.filtersContainer, {
              [classes.filtersVisible]: showFilters,
              [classes.filtersHidden]: !showFilters,
            })}
          >
            <div className={classes.filterHeader}>
              <Grid container spacing={2} alignItems="center">
                {/* Search Field */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  className={classes.filterItem}
                >
                  <TextField
                    size="small"
                    fullWidth
                    placeholder={i18n.t(
                      "reservasiRuangTunggu.searchPlaceholder"
                    )}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "gray" }} />
                        </InputAdornment>
                      ),
                      "aria-label": "search",
                    }}
                    label={i18n.t("reservasiRuangTunggu.table.name")}
                  />
                </Grid>

                {/* Date Picker */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  className={classes.filterItem}
                >
                  <KeyboardDatePicker
                    size="small"
                    fullWidth
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    placeholder={i18n.t("reservasiRuangTunggu.datePlaceholder")}
                    value={selectedDate ? new Date(selectedDate) : null}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    clearable
                    label={i18n.t("reservasiRuangTunggu.dateLabel")}
                    InputProps={{
                      startAdornment: <></>,
                      "aria-label": "search",
                    }}
                  />
                </Grid>

                {/* Add additional filters here if needed */}
              </Grid>
              {/* Close Filters Button */}
              <IconButton
                className={classes.iconButton}
                onClick={toggleFilters}
                aria-label={i18n.t("reservasiRuangTunggu.actions.hideFilters")}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </Collapse>

        {/* ReservasiRuangTunggu Table */}
        <Paper
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >
          <Table size="small">
            <TableHead className={classes.stickyHeader}>
              <TableRow>
                <TableCell>
                  {i18n.t("reservasiRuangTunggu.table.name")}
                </TableCell>
                <TableCell>
                  {i18n.t("reservasiRuangTunggu.table.contact_person")}
                </TableCell>
                <TableCell>
                  {i18n.t("reservasiRuangTunggu.table.police_no")}
                </TableCell>
                <TableCell>
                  {i18n.t("reservasiRuangTunggu.table.birth_date")}
                </TableCell>
                <TableCell>
                  {i18n.t("reservasiRuangTunggu.table.jam_kedatangan")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("reservasiRuangTunggu.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.no_booking}
                  className={clsx({
                    [classes.yellowRow]: Number(row.status) === 70,
                  })}
                >
                  {/* Customer Column with Truncated Text */}
                  <TableCell>
                    <Tooltip title={row.customer} arrow>
                      <span className={classes.truncatedText}>
                        {row.customer}
                      </span>
                    </Tooltip>
                  </TableCell>

                  {/* Contact Person Column with Truncated Text */}
                  <TableCell>
                    <Tooltip title={row.contact_person} arrow>
                      <span className={classes.truncatedText}>
                        {row.contact_person}
                      </span>
                    </Tooltip>
                  </TableCell>

                  {/* Police No Column */}
                  <TableCell>{row.police_no}</TableCell>

                  {/* Birth Date Column */}
                  <TableCell>{row.birthday}</TableCell>

                  {/* Jam Kedatangan Column */}
                  <TableCell>{row.jam_kedatangan}</TableCell>

                  {/* Actions Column */}
                  <TableCell align="center">
                    {/* WhatsApp IconButton */}
                    <IconButton
                      size="small"
                      className={classes.iconButton}
                      onClick={() => {
                        setSelectedCustomerId(row.police_no);
                        setProfileCustomerModalOpen(true);
                      }}
                      aria-label={i18n.t(
                        "reservasiRuangTunggu.actions.openProfile"
                      )}
                    >
                      <AccountBoxIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      className={classes.iconButton}
                      onClick={() => {
                        setContactTicket(row);
                        setNewTicketModalOpen(true);
                      }}
                      aria-label={i18n.t(
                        "reservasiRuangTunggu.actions.sendWhatsApp"
                      )}
                    >
                      <WhatsAppIcon />
                    </IconButton>

                    {/* Edit IconButton */}
                    <IconButton
                      size="small"
                      onClick={() => handleEditContact(row.no_booking)}
                      aria-label={i18n.t(
                        "reservasiRuangTunggu.actions.editContact"
                      )}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}

              {/* Loading Skeleton */}
              {loading && <TableRowSkeleton columns={6} align="left" />}

              {/* No Results Found */}
              {!loading && rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    {i18n.t("reservasiRuangTunggu.noResults")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </MainContainer>
    </MuiPickersUtilsProvider>
  );
};

export default ReservasiRuangTunggu;
