import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getBackendURL } from "../../services/config";

// Contoh data kendaraan (sebaiknya diambil dari backend atau context)
const vehicleData = [
  {
    id: "1",
    name: "THS 1",
    description: "Suhirman",
  },
  {
    id: "2",
    name: "THS 2",
    description: "-",
  },
  {
    id: "3",
    name: "THS 3",
    description: "-",
  },
  {
    id: "4",
    name: "THS 4",
    description: "-",
  },
];

const ThsSchedule = () => {
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [serviceDate, setServiceDate] = useState(new Date());
  const [serviceDescription, setServiceDescription] = useState("");
  const [scheduledServices, setScheduledServices] = useState([]);

  // Fetch scheduled services dari API
  useEffect(() => {
    const fetchScheduledServices = async () => {
      try {
        const response = await fetch(
          `${getBackendURL()}/ths/get-scheduled-services`
        );
        if (!response.ok) {
          throw new Error("Gagal mengambil data jadwal servis");
        }
        const data = await response.json();
        setScheduledServices(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchScheduledServices();

    const pollingInterval = setInterval(fetchScheduledServices, 10000); // Refresh setiap 10 detik
    return () => clearInterval(pollingInterval);
  }, []);

  // Handle submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedVehicle || !serviceDate) {
      alert("Silakan pilih kendaraan dan tanggal servis.");
      return;
    }

    const newService = {
      vehicleId: selectedVehicle,
      date: serviceDate.toISOString(),
      description: serviceDescription,
    };

    try {
      const response = await fetch(`${getBackendURL()}/ths/schedule-service`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newService),
      });

      if (!response.ok) {
        throw new Error("Gagal menjadwalkan servis");
      }

      const savedService = await response.json();
      setScheduledServices((prev) => [...prev, savedService]);
      // Reset form
      setSelectedVehicle("");
      setServiceDate(new Date());
      setServiceDescription("");
      alert("Jadwal servis berhasil ditambahkan!");
    } catch (error) {
      console.error("Error:", error);
      alert("Terjadi kesalahan saat menjadwalkan servis.");
    }
  };

  // Handle hapus jadwal servis
  const handleDelete = async (serviceId) => {
    if (
      !window.confirm("Apakah Anda yakin ingin menghapus jadwal servis ini?")
    ) {
      return;
    }

    try {
      const response = await fetch(
        `${getBackendURL()}/ths/delete-service/${serviceId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Gagal menghapus jadwal servis");
      }

      setScheduledServices((prev) =>
        prev.filter((service) => service.id !== serviceId)
      );
      alert("Jadwal servis berhasil dihapus!");
    } catch (error) {
      console.error("Error:", error);
      alert("Terjadi kesalahan saat menghapus jadwal servis.");
    }
  };

  return (
    <div style={{ display: "flex", padding: "20px" }}>
      {/* Form Penjadwalan Servis */}
      <div
        style={{
          width: "400px",
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 1px 6px rgba(0, 0, 0, 0.1)",
          marginRight: "20px",
        }}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Jadwal Servis Kendaraan
        </h2>
        <form onSubmit={handleSubmit}>
          {/* Pemilihan Kendaraan */}
          <div style={{ marginBottom: "15px" }}>
            <label
              htmlFor="vehicle"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Pilih Kendaraan:
            </label>
            <select
              id="vehicle"
              value={selectedVehicle}
              onChange={(e) => setSelectedVehicle(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              required
            >
              <option value="">-- Pilih Kendaraan --</option>
              {vehicleData.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.id}>
                  {vehicle.name} - {vehicle.description}
                </option>
              ))}
            </select>
          </div>

          {/* Pemilihan Tanggal dan Waktu */}
          <div style={{ marginBottom: "15px" }}>
            <label
              htmlFor="serviceDate"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Tanggal dan Waktu Servis:
            </label>
            <DatePicker
              selected={serviceDate}
              onChange={(date) => setServiceDate(date)}
              showTimeSelect
              dateFormat="Pp"
              minDate={new Date()}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              required
            />
          </div>

          {/* Deskripsi Servis */}
          <div style={{ marginBottom: "15px" }}>
            <label
              htmlFor="description"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Deskripsi Pekerjaan:
            </label>
            <textarea
              id="description"
              value={serviceDescription}
              onChange={(e) => setServiceDescription(e.target.value)}
              rows="3"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              placeholder="Masukkan deskripsi pekerjaan servis..."
            ></textarea>
          </div>

          {/* Tombol Submit */}
          <button
            type="submit"
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#28a745",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            Jadwalkan Servis
          </button>
        </form>
      </div>

      {/* Daftar Jadwal Servis */}
      <div
        style={{
          flex: 1,
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 1px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Daftar Jadwal Servis
        </h2>
        {scheduledServices.length === 0 ? (
          <p style={{ textAlign: "center" }}>
            Tidak ada jadwal servis yang ditetapkan.
          </p>
        ) : (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Kendaraan
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Tanggal & Waktu
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Deskripsi
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody>
              {scheduledServices.map((service) => {
                const vehicle = vehicleData.find(
                  (v) => v.id === service.vehicleId
                );
                return (
                  <tr key={service.id}>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      {vehicle
                        ? `${vehicle.name} - ${vehicle.description}`
                        : "Unknown"}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      {new Date(service.date).toLocaleString()}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      {service.description || "-"}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      <button
                        onClick={() => handleDelete(service.id)}
                        style={{
                          backgroundColor: "#dc3545",
                          color: "white",
                          padding: "5px 10px",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        Hapus
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ThsSchedule;
