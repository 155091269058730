// import { messages as portugueseMessages } from "./pt";
import { messages as englishMessages } from "./en";
import { messages as indoensianMessages } from "./id";
// import { messages as spanishMessages } from "./es";

const messages = {
  // ...portugueseMessages,
  ...englishMessages,
  ...indoensianMessages,
  // ...spanishMessages,
};

export { messages };
