const messages = {
  en: {
    translations: {
      validation: {
        tooShort: "This field is too short.",
        tooLong: "This field is too long.",
        required: "This field is required.",
        invalidEmail: "Please enter a valid email address.",
      },
      signup: {
        title: "Sign Up",
        toasts: {
          success: "User created successfully! Please log in!!!",
          fail: "Error creating user. Please check the provided information.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Sign Up",
          login: "Already have an account? Log in!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Log In",
          register: "Don't have an account? Sign up!",
        },
      },
      companies: {
        title: "Register Company",
        form: {
          name: "Company Name",
          plan: "Plan",
          token: "Token",
          submit: "Register",
          success: "Company created successfully!",
        },
      },
      auth: {
        toasts: {
          success: "Login successful!",
        },
        token: "Token",
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Appointments today: ",
          },
        },
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "WhatsApp connection successfully deleted!",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? This action cannot be undone.",
          disconnectTitle: "Disconnect",
          disconnectMessage:
            "Are you sure? You will need to scan the QR code again.",
        },
        buttons: {
          add: "Add WhatsApp",
          disconnect: "Disconnect",
          tryAgain: "Try again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connecting",
        },
        toolTips: {
          disconnected: {
            title: "Failed to start WhatsApp session",
            content:
              "Make sure your phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code scan",
            content:
              "Click the 'QR CODE' button and scan the QR Code with your phone to start the session",
          },
          connected: {
            title: "Connection established!",
          },
          timeout: {
            title: "Connection with the phone was lost",
            content:
              "Make sure your phone is connected to the internet and WhatsApp is open, or click the 'Disconnect' button to get a new QR Code",
          },
        },
        table: {
          name: "Name",
          status: "Status",
          lastUpdate: "Last update",
          default: "Default",
          actions: "Actions",
          session: "Session",
        },
      },
      internalChat: {
        title: "Internal Chat",
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        form: {
          name: "Name",
          default: "Default",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      qrCode: {
        message: "Scan the QR Code to start the session",
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact successfully deleted!",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Delete",
          importTitle: "Import contacts",
          deleteMessage:
            "Are you sure you want to delete this contact? All related appointments will be lost.",
          importMessage: "Do you want to import all phone contacts?",
        },
        buttons: {
          import: "Import Contacts",
          add: "Add Contact",
        },
        table: {
          name: "Name",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Actions",
        },
      },
      reservasiRuangTunggu: {
        noResults: "No results found.",
        title: "Reservasi Ruang Tunggu",
        searchPlaceholder: "Search...",
        datePlaceholder: "Select a date",
        dateLabel: "Date",
        actions: {
          openProfile: "Open Profile",
        },
        table: {
          name: "Name",
          contact_person: "Contact Person",
          police_no: "Police No",
          birth_date: "Birthday",
          jam_kedatangan: "Jam",
          status: "Status",
          actions: "Actions",
        },
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this?",
          deleteMessage: "This action cannot be undone.",
        },
      },
      profileCustomerModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          customer: "Nama Customer",
          hobi: "Hobi",
          pekerjaan: "Pekerjaan",
          makanan_fav: "Makanan Favorite",
          minuman_fav: "Minuman Favorite",
          alamat: "Alamat",
          number: "WhatsApp Number",
          customer_group: "Customer Group",
          telephone_wa_cp: "Telephone WA",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",
          disableBot: "Disable chatbot",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          name: "Name",
          number: "WhatsApp Number",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",
          disableBot: "Disable chatbot",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      queueModal: {
        title: {
          add: "Add queue",
          edit: "Edit queue",
        },
        form: {
          name: "Name",
          color: "Color",
          greetingMessage: "Greeting message",
          completionMessage: "Completion message",
          outOfHoursMessage: "Out of hours message",
          ratingMessage: "Rating message",
          transferMessage: "Transfer message",
          token: "Token",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          attach: "Attach File",
        },
        serviceHours: {
          dayWeek: "Day of the week",
          startTimeA: "Start Time - Shift A",
          endTimeA: "End Time - Shift A",
          startTimeB: "Start Time - Shift B",
          endTimeB: "End Time - Shift B",
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
          sunday: "Sunday",
        },
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: "Profile",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      scheduleModal: {
        title: {
          add: "New Appointment",
          edit: "Edit Appointment",
        },
        form: {
          body: "Message",
          contact: "Contact",
          sendAt: "Appointment Date",
          sentAt: "Sent Date",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Appointment saved successfully.",
      },
      tagModal: {
        title: {
          add: "New Tag",
          edit: "Edit Tag",
          addKanban: "New Lane",
          editKanban: "Edit Lane",
        },
        form: {
          name: "Name",
          color: "Color",
          kanban: "Kanban",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Tag saved successfully.",
        successKanban: "Lane saved successfully.",
      },
      chat: {
        noTicketMessage: "Select a ticket to start chatting.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop: "DRAG AND DROP FILES BELOW",
          titleFileList: "File list",
        },
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Queues",
      },
      tickets: {
        toasts: {
          deleted: "The ticket you were on has been deleted.",
        },
        notification: {
          message: "Message from",
        },
        tabs: {
          open: { title: "Open" },
          closed: { title: "Closed" },
          search: { title: "Search" },
        },
        search: {
          placeholder: "Search tickets and messages",
        },
        buttons: {
          showAll: "All",
        },
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldLabel: "Type to search users",
        fieldQueueLabel: "Transfer to queue",
        fieldQueuePlaceholder: "Select a queue",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      ticketsList: {
        pendingHeader: "Pending",
        assignedHeader: "In Progress",
        noTicketsTitle: "Nothing here!",
        noTicketsMessage: "No tickets found with this status or search term",
        buttons: {
          accept: "Accept",
        },
      },
      newTicketModal: {
        title: "Create Ticket",
        fieldLabel: "Type to search contact",
        add: "Add",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Connections",
          todolist: "Todolist",
          tickets: "Tickets",
          quickMessages: "Quick Responses",
          contacts: "Contacts",
          queues: "Queues & Chatbot",
          tags: "Tags",
          administration: "Administration",
          service: "Service",
          users: "Users",
          settings: "Settings",
          helps: "Help",
          messagesAPI: "API",
          schedules: "Appointments",
          campaigns: "Campaigns",
          announcements: "Announcements",
          chats: "Internal Chat",
          financeiro: "Financial",
          logout: "Logout",
          management: "Management",
          ths: "THS",
          kanban: "Kanban",
          annoucements: "Annoucements",
          importData: "Import Data",
          thsTracker: "THS Tracker",
          thsSchedule: "THS Schedule",
          ruangTunggu: "Ruang Tunggu",
        },
        appBar: {
          i18n: {
            language: "English",
            language_short: "EN",
          },
          user: {
            profile: "Profile",
            darkmode: "Dark mode",
            lightmode: "Light mode",
            language: "Select language",
            about: "About",
            logout: "Logout",
          },
        },
      },
      messagesAPI: {
        title: "API",
        textMessage: {
          number: "Number",
          body: "Message",
          token: "Registered token",
        },
        mediaMessage: {
          number: "Number",
          body: "File name",
          media: "File",
          token: "Registered token",
        },
      },
      notifications: {
        noTickets: "No notifications.",
      },
      quickMessages: {
        title: "Quick Responses",
        buttons: {
          add: "New Response",
        },
        dialog: {
          shortcode: "Shortcut",
          message: "Response",
        },
      },
      kanban: {
        title: "Kanban",
        searchPlaceholder: "Search",
        subMenus: {
          list: "Board",
          tags: "Lanes",
        },
      },
      todoLists: {
        title: "Todolist",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Todolist?",
          deleteMessage: "This action cannot be undone.",
        },
        table: {
          name: "Name",
          color: "Color",
          tickets: "Tickets",
          actions: "Actions",
        },
        labels: {
          add: "New Todolist",
        },
        buttons: {
          add: "Add",
          save: "Save",
        },
        toasts: {
          deleted: "Todolist successfully deleted.",
        },
      },
      tagsKanban: {
        title: "Lanes",
        laneDefault: "Open",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Lane?",
          deleteMessage: "This action cannot be undone.",
        },
        table: {
          name: "Name",
          color: "Color",
          tickets: "Tickets",
          actions: "Actions",
        },
        buttons: {
          add: "New Lane",
        },
        toasts: {
          deleted: "Lane successfully deleted.",
        },
      },
      contactLists: {
        title: "Contact Lists",
        table: {
          name: "Name",
          contacts: "Contacts",
          actions: "Actions",
        },
        buttons: {
          add: "New List",
        },
        dialog: {
          name: "Name",
          company: "Company",
          okEdit: "Edit",
          okAdd: "Add",
          add: "Add",
          edit: "Edit",
          cancel: "Cancel",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "This action cannot be undone.",
        },
        toasts: {
          deleted: "Record deleted",
        },
      },
      contactListItems: {
        title: "Contacts",
        searchPlaceholder: "Search",
        buttons: {
          add: "New",
          lists: "Lists",
          import: "Import",
        },
        dialog: {
          name: "Name",
          number: "Number",
          whatsapp: "WhatsApp",
          email: "E-mail",
          okEdit: "Edit",
          okAdd: "Add",
          add: "Add",
          edit: "Edit",
          cancel: "Cancel",
        },
        table: {
          name: "Name",
          number: "Number",
          whatsapp: "WhatsApp",
          email: "E-mail",
          actions: "Actions",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "This action cannot be undone.",
          importMessage:
            "Do you want to import contacts from this spreadsheet?",
          importTitle: "Import",
        },
        toasts: {
          deleted: "Record deleted",
        },
      },
      campaigns: {
        title: "Campaigns",
        searchPlaceholder: "Search",
        buttons: {
          add: "New Campaign",
          contactLists: "Contact Lists",
        },
        table: {
          name: "Name",
          whatsapp: "Connection",
          contactList: "Contact List",
          status: "Status",
          scheduledAt: "Scheduled At",
          completedAt: "Completed At",
          confirmation: "Confirmation",
          actions: "Actions",
        },
        dialog: {
          new: "New Campaign",
          update: "Edit Campaign",
          readonly: "Read Only",
          form: {
            name: "Name",
            message1: "Message 1",
            message2: "Message 2",
            message3: "Message 3",
            message4: "Message 4",
            message5: "Message 5",
            confirmationMessage1: "Confirmation Message 1",
            confirmationMessage2: "Confirmation Message 2",
            confirmationMessage3: "Confirmation Message 3",
            confirmationMessage4: "Confirmation Message 4",
            confirmationMessage5: "Confirmation Message 5",
            messagePlaceholder: "Message content",
            whatsapp: "Connection",
            status: "Status",
            scheduledAt: "Scheduled At",
            confirmation: "Confirmation",
            contactList: "Contact List",
          },
          buttons: {
            add: "Add",
            edit: "Update",
            okAdd: "Ok",
            cancel: "Cancel Campaign",
            restart: "Restart Campaign",
            close: "Close",
            attach: "Attach File",
          },
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "This action cannot be undone.",
        },
        toasts: {
          success: "Operation successfully performed",
          cancel: "Campaign canceled",
          restart: "Campaign restarted",
          deleted: "Record deleted",
        },
      },
      announcements: {
        title: "Announcements",
        searchPlaceholder: "Search",
        buttons: {
          add: "New Announcement",
          contactLists: "Contact Lists",
        },
        table: {
          priority: "Priority",
          title: "Title",
          text: "Text",
          mediaName: "File",
          status: "Status",
          actions: "Actions",
        },
        dialog: {
          edit: "Edit Announcement",
          add: "New Announcement",
          update: "Edit Announcement",
          readonly: "Read Only",
          form: {
            priority: "Priority",
            title: "Title",
            text: "Text",
            mediaPath: "File",
            status: "Status",
          },
          buttons: {
            add: "Add",
            edit: "Update",
            okAdd: "Ok",
            cancel: "Cancel",
            close: "Close",
            attach: "Attach File",
          },
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "This action cannot be undone.",
        },
        toasts: {
          success: "Operation successfully performed",
          deleted: "Record deleted",
        },
      },
      campaignsConfig: {
        title: "Campaign Configurations",
      },
      queues: {
        title: "Queues & Chatbot",
        table: {
          name: "Name",
          color: "Color",
          greeting: "Greeting message",
          actions: "Actions",
        },
        buttons: {
          add: "Add queue",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? This action cannot be undone! Tickets from this queue will continue to exist, but will no longer have any assigned queue.",
        },
      },
      queueSelect: {
        inputLabel: "Queues",
      },
      import: {
        serviceGr: {
          title: "Import Service GR",
          table: {
            name: "Name",
            count: "Count",
            actions: "Actions",
          },
        },
      },
      users: {
        title: "Users",
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          actions: "Actions",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User successfully deleted.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Open tickets of this user will be moved to the queue.",
        },
      },
      helps: {
        title: "Help Center",
      },
      about: {
        aboutthe: "About the",
        copyright: "© 2024 - Powered by ticketz",
        buttonclose: "Close",
        title: "About ticketz",
        abouttitle: "Origin and improvements",
        aboutdetail:
          "Ticketz is an indirect derivative of the Whaticket project with improvements shared by the developers of the EquipeChat system through the VemFazer channel on YouTube, later improved by Claudemir Todo Bom",
        aboutauthorsite: "Author's site",
        aboutwhaticketsite: "Whaticket Community site on GitHub",
        aboutvemfazersite: "Vem Fazer channel site on GitHub",
        licenseheading: "Open Source License",
        licensedetail:
          "Ticketz is licensed under the GNU Affero General Public License version 3, which means that any user who has access to this application has the right to access the source code. More information can be found in the links below:",
        licensefulltext: "Full license text",
        licensesourcecode: "Ticketz source code",
      },
      schedules: {
        title: "Appointments",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Appointment?",
          deleteMessage: "This action cannot be undone.",
        },
        table: {
          contact: "Contact",
          body: "Message",
          sendAt: "Appointment Date",
          sentAt: "Sent Date",
          status: "Status",
          actions: "Actions",
        },
        buttons: {
          add: "New Appointment",
        },
        toasts: {
          deleted: "Appointment successfully deleted.",
        },
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Tag?",
          deleteMessage: "This action cannot be undone.",
        },
        table: {
          name: "Name",
          color: "Color",
          tickets: "Records",
          actions: "Actions",
          id: "Id",
          kanban: "Kanban",
        },
        buttons: {
          add: "New Tag",
        },
        toasts: {
          deleted: "Tag successfully deleted.",
        },
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          userCreation: {
            name: "User creation",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
        },
        validations: {
          title: "validations",
          options: {
            enabled: "enabled",
            disabled: "disabled",
          },
        },
        OfficeManagement: {
          title: "Office Management",
          options: {
            disabled: "disabled",
            ManagementByDepartment: "Management By Department",
            ManagementByCompany: "Management By Company",
          },
        },
        IgnoreGroupMessages: {
          title: "Ignore Group Messages",
          options: {
            enabled: "enabled",
            disabled: "disabled",
          },
        },
        VoiceAndVideoCalls: {
          title: "Voice and video calls",
          options: {
            enabled: "Ignore",
            disabled: "unavailability report",
          },
        },
        AutomaticChatbotOutput: {
          title: "Automatic Chatbot Output",
          options: {
            enabled: "enabled",
            disabled: "disabled",
          },
        },
        QuickMessages: {
          title: "Quick Messages",
          options: {
            enabled: "By company",
            disabled: "By User",
          },
        },
        AllowRegistration: {
          title: "Allow Registration",
          options: {
            enabled: "enabled",
            disabled: "disabled",
          },
        },
        FileDownloadLimit: {
          title: "File Download Limit (MB)",
        },
        messageVisibility: {
          title: "Message Visibility",
          options: {
            respectMessageQueue: "Respect queue of message",
            respectTicketQueue: "Respect queue of ticket",
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            return: "Return",
            resolve: "Resolve",
            reopen: "Reopen",
            accept: "Accept",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Type a message",
        placeholderClosed: "Reopen or accept this ticket to send a message.",
        signMessage: "Sign",
        replying: "Replying",
        editing: "Editing",
      },
      message: {
        edited: "Edited",
      },
      contactDrawer: {
        header: "Contact details",
        buttons: {
          edit: "Edit contact",
        },
        extraInfo: "Other information",
      },
      ticketOptionsMenu: {
        schedule: "Appointment",
        delete: "Delete",
        transfer: "Transfer",
        registerAppointment: "Contact Notes",
        appointmentsModal: {
          title: "Contact Notes",
          textarea: "Note",
          placeholder: "Enter the information you want to register here",
        },
        confirmationModal: {
          title: "Delete the contact's ticket",
          message:
            "Attention! All messages related to the ticket will be lost.",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      messageOptionsMenu: {
        delete: "Delete",
        edit: "Edit",
        history: "History",
        reply: "Reply",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be undone.",
        },
      },
      messageHistoryModal: {
        close: "Close",
        title: "Message edit history",
      },
      presence: {
        unavailable: "Unavailable",
        available: "Available",
        composing: "Composing...",
        recording: "Recording...",
        paused: "Paused",
      },
      privacyModal: {
        title: "Edit Whatsapp Privacy",
        buttons: {
          cancel: "Cancel",
          okEdit: "Save",
        },
        form: {
          menu: {
            all: "All",
            none: "Nobody",
            contacts: "My contacts",
            contact_blacklist: "Selected contacts",
            match_last_seen: "Match Last Seen",
            known: "Known",
            disable: "Disabled",
            hrs24: "24 Hours",
            dias7: "7 Days",
            dias90: "90 Days",
          },
          readreceipts: "To update the Read Receipts privacy",
          profile: "To update the Profile Picture privacy",
          status: "To update the Messages privacy",
          online: "To update the Online privacy",
          last: "To update the LastSeen privacy",
          groupadd: "To update the Groups Add privacy",
          calladd: "To update the Call Add privacy",
          disappearing: "To update the Default Disappearing Mode",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "There must be at least one default WhatsApp.",
        ERR_NO_DEF_WAPP_FOUND:
          "No default WhatsApp found. Check the connections page.",
        ERR_WAPP_NOT_INITIALIZED:
          "This WhatsApp session was not initialized. Check the connections page.",
        ERR_WAPP_CHECK_CONTACT:
          "Unable to verify WhatsApp contact. Check the connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid WhatsApp number.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Unable to download media from WhatsApp. Check the connections page.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_SENDING_WAPP_MSG:
          "Error sending WhatsApp message. Check the connections page.",
        ERR_DELETE_WAPP_MSG: "Unable to delete WhatsApp message.",
        ERR_EDITING_WAPP_MSG: "Unable to edit WhatsApp message.",
        ERR_OTHER_OPEN_TICKET:
          "There is already an open ticket for this contact.",
        ERR_SESSION_EXPIRED: "Session expired. Please log in.",
        ERR_USER_CREATION_DISABLED:
          "User creation has been disabled by the administrator.",
        ERR_NO_PERMISSION:
          "You do not have permission to access this resource.",
        ERR_DUPLICATED_CONTACT: "There is already a contact with this number.",
        ERR_NO_SETTING_FOUND: "No setting found with this ID.",
        ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
        ERR_NO_TICKET_FOUND: "No ticket found with this ID.",
        ERR_NO_USER_FOUND: "No user found with this ID.",
        ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
        ERR_CREATING_MESSAGE: "Error creating message in the database.",
        ERR_CREATING_TICKET: "Error creating ticket in the database.",
        ERR_FETCH_WAPP_MSG:
          "Error fetching the message on WhatsApp, it may be too old.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "This color is already in use, choose another.",
        ERR_WAPP_GREETING_REQUIRED:
          "The greeting message is mandatory when there is more than one queue.",
      },
      ticketz: {
        support: {
          title: "Support the Ticketz project",
          mercadopagotitle: "Credit Card",
          recurringbrl: "Recurring donation in R$",
          paypaltitle: "Credit Card",
          international: "International in US$",
        },
      },
    },
  },
};

export { messages };
